
import Vue, { VueConstructor } from "vue";

import FormMixins from "@/mixins/form";
import Income from "@/models/income";
import Currency from "@/models/currency";
import Provider from "@/models/provider";
import Growing from "@/models/growing";
import Harvest from "@/models/harvest";

import { real_op, dollar_op } from "@/currencyOptions";
import { ICurrencyOptions, IEnums } from "@/types/form-types";
import { IParams } from "@/types/store-types";
import { crudService } from "@/services/crud";

export default (
  Vue as VueConstructor<Vue & InstanceType<typeof FormMixins>>
).extend({
  props: {
    ownership_id: Number,
    harvest_id: Number,
    growing_id: Number,
    cattleLotByOwnership: Array,
    cattleLotSelected: Object,
    gColor: String,
  },
  mixins: [FormMixins],
  data() {
    return {
      income: new Income(),
      currencySelected: undefined as Currency | undefined,
      provider: undefined as Provider | undefined,
      providers: [] as Provider[],
      growingSelected: undefined as Growing | undefined,
      // Indica que o form foi aberto na rentabilidade
      fromProfit: false,
      // ENUMS
      income_types: [
        {
          name: "Recebido",
          value: "RECEIVED",
        },
        {
          name: "Pendente",
          value: "PENDING",
        },
      ] as IEnums[],
      income_from: [
        {
          name: "Pluma",
          value: "FIBER",
        },
        {
          name: "Caroço",
          value: "SEED",
        },
      ] as IEnums[],
    };
  },
  async mounted() {
    const params: IParams = {
      my: true,
      checkDelete: true,
    };
    try {
      // Busca os comprador
      const providers = await crudService.getSimpleResourceClass(
        Provider,
        Provider.getResource(),
        params
      );
      if(providers) this.providers = providers.objects;
    } catch (error) {
      this.$alertError("Erro ao carregar os fornecedores");
    }
  },
  methods: {
    async save(): Promise<void> {
      if (this.validate()) {
        if (typeof this.provider === "string") {
          const providerSave = new Provider({
            name: this.provider,
            is_deleted: false,
            user_id: this.user.id,
          });
          try {
            const provider = await crudService.saveClass(
              Provider,
              Provider.getResource(),
              providerSave
            );
            if(provider.id){
              this.income.provider_id = provider.id;
              this.saveIncome();
            }else{
              this.$alertError("Problema ao salvar o fornecedor");
              console.error("Provider without id");
            }
          } catch (error) {
            this.$alertError("Problema ao salvar o fornecedor");
            console.error(error);
          }
        } else {
          if(this.provider?.id) {
            this.income.provider_id = this.provider.id;
            this.saveIncome();
          }else {
            this.$alertError("Erro ao atrelar o fornecedor com a receita");
            console.error("No provider id to assign to income.provider_id");
          }
        }
      }
    },
    async saveIncome(): Promise<void> {
      this.setResource("income");
      this.income.ownership_id = this.ownership_id;
      if(this.growingSelected?.id)
        this.income.growing_id = this.growingSelected.id;
      // Mixins
      if (this.cattleSelected) {
        // FIXME: FIX VALUE
        if(this.income.price && this.weightCarcassArrobas)
          this.income.value =
            this.income.price * this.weightCarcassArrobas;
        this.income.currency_id = this.currencies.find(
          (c: Currency) => c.identifier === "R"
        ).id;
        // this.income.value = this.lotValue;
        this.income.reference_value = 1;
      } else {
        if(this.income.departure_date)
          this.income.departure_date = this.dateISONotNull(
            this.income.departure_date
          );
        // O combobox retorna um objeto
        // seleciono apenas o id para salvar
        if(this.currencySelected?.id)
          this.income.currency_id = this.currencySelected.id;
      }
      // Verifica se uma data foi selecionada
      // Senão utiliza a padrao
      if(this.income.payment_date)
        this.income.payment_date = this.dateISONotNull(this.income.payment_date);

      try {
        const income = await crudService.saveClass(
          Income,
          Income.getResource(),
          this.income
        );
        this.$alertSuccess(this.componentsText.mSuccess);
        // Se a chamada veio da tela de rentabilidade
        // emit outro evento
        if (this.fromProfit) {
          this.$emit("searchProfit");
        } else {
          this.$emit("updateResourceList", income);
        }
        this.close();
      } catch (error) {
        this.$alertError(this.componentsText.mError);
        console.error(error);
      }
    },
    initialize(): void {
      this.income = new Income();
      // Atualiza o dia do pagamento
      // para 30 dias
      this.initPayDate();
      this.growingSelected = undefined;
      this.currencySelected = undefined;
      this.provider = undefined;
      this.fromProfit = false;
    },
    changeCurrency(item: Currency): void {
      if (item.identifier === "R") this.income.reference_value = 1;
      else this.income.reference_value = undefined;
    },
    calcTotal(value: string): void {
      if (this.income.amount && this.income.price) {
        this.income.value = this.income.price * parseInt(value);
      }
    },
    toUpdate(item: Income): void {
      this.show = true;
      this.growingSelected = this.growings.find((c: Growing) => {
        return c.id === item.growing_id;
      });
      this.currencySelected = this.currencies.find((c: Currency) => {
        return c.id === item.currency_id;
      });
      this.provider = this.providers.find((p) => p.id === item.provider_id);
      this.$nextTick(() => {
        this.income = new Income(item);
        this.lotValue = this.income.value;
      });
    },
    initPayDate(): void {
      this.income.payment_date = this.$luxon(new Date().toISOString())
        .plus({ months: 1 })
        .toString()
        .substr(0, 10);
    },
    updatePayDate(): void {
      if(this.income.departure_date)
        this.income.payment_date = this.$luxon(this.income.departure_date)
          .plus({ months: 1 })
          .toString()
          .substr(0, 10);
    },
    callFromProfit(): void {
      this.show = true;
      this.fromProfit = true;
      // seleciona o cultura do profit
      this.growingSelected = this.growings.find(
        (g: Growing) => g.id === this.growing_id
      );
      this.$nextTick(() => {
        if (this.cattleSelected) {
          this.income.cattle_lot_id = this.cattleLotSelected.id;
          this.income.harvest_id = undefined;
          this.income.cattle_weight =
            this.cattleLotSelected.final_weight_finishing;
          this.income.cattle_yield_carcass =
            this.cattleLotSelected.yield_carcass;
        } else {
          this.income.harvest_id = this.harvests.find(
            (h: Harvest) => h.id === this.harvest_id
          )?.id;
        }
      });
    },
  },
  computed: {
    componentsText() {
      if (this.income && this.income.id) {
        return {
          buttonText: "Atualizar",
          mSuccess: "Receita atualizada com sucesso!",
          mError: "Houve um problema ao atualizar a Receita",
        };
      } else {
        return {
          buttonText: "Adicionar",
          mSuccess: "Receita inserida com sucesso!",
          mError: "Houve um problema ao inserir a receita",
        };
      }
    },
    currencyFiltered(): Currency[] {
      if (this.currencies) {
        return this.currencies.filter(
          (c: Currency) => c.identifier === "R" || c.identifier === "D"
        );
      }
      return [];
    },
    options(): ICurrencyOptions {
      if (this.currencySelected) {
        if (this.currencySelected.identifier === "D") return dollar_op;
      }
      return real_op;
    },
    hideReferenceValue(): boolean {
      if (this.currencySelected) {
        return this.currencySelected.identifier === "R" ? false : true;
      }
      return true;
    },
    cottonSelected(): boolean {
      return this.growingSelected ? this.growingSelected.isCotton : false;
    },
    cattleSelected(): boolean {
      return this.growingSelected ? this.growingSelected.isCattle : false;
    },
    label_value_sacks(): string {
      if (this.cottonSelected) {
        if (this.income.income_from === "SEED") {
          return "Valor do Quilo*";
        }
        return "Valor da arroba*";
      }
      return "Valor da saca*";
    },
    sufixies(): string {
      if (this.cottonSelected) {
        if (this.income.income_from === "SEED") {
          return "Quilos (kg)";
        }
        return "Arroba (@)";
      }
      return "Sacas (sc)";
    },
    weightCarcassKilos(): number | undefined {
      if (this.income.cattle_weight && this.income.cattle_yield_carcass) {
        return (
          this.income.cattle_weight *
          (this.income.cattle_yield_carcass / 100)
        );
      }
      return undefined;
    },
    weightCarcassArrobas(): number | undefined {
      if (this.income.cattle_weight && this.income.cattle_yield_carcass) {
        return (
          (this.income.cattle_weight *
            (this.income.cattle_yield_carcass / 100)) /
          15
        );
      }
      return undefined;
    },
    lotValue: {
      get(): number | undefined {
        if (
          this.income.amount &&
          this.income.price &&
          this.weightCarcassArrobas
        ) {
          return (
            this.income.price *
            this.weightCarcassArrobas *
            this.income.amount
          );
        }
        return undefined;
      },
      set(value: number): void {
        this.income.value = value;
      },
    },
    valueByAnimal(): number | undefined {
      if (this.income.price && this.weightCarcassArrobas) {
        return (
          this.income.price * this.weightCarcassArrobas
        );
      }
      return undefined;
    },
  },
});
